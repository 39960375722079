











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SubmissionStatus extends Vue {
  @Prop() private status: string;

  @Prop() private reportColour: string;

  private statusColours = {
    pending: 'warning',
    ammend: 'error',
    draft: 'purple',
    complete: 'success',
  };
}
