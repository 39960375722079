var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('s-layout-default',{attrs:{"title":"Snapshot overview","icon":"explore","dense":""}},[_c('v-container',[(_vm.snackbar)?_c('v-snackbar',{attrs:{"timeout":_vm.longDelay,"color":_vm.snackbar.color,"bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('TodaysReports')],1),_c('v-col',{attrs:{"md":"8","sm":"12"}},[_c('s-card',{attrs:{"title":"Submissions","dense":"","icon":"view_list","show-icon":"","flush":""}},[_c('s-table',{staticClass:"submissions-table",attrs:{"headers":_vm.submissionHeaders,"data":_vm.currentItems,"item-key":"id","dynamic-data":"","number-of-items":_vm.totalSubmissions,"pagination":_vm.pagination,"page-size-options":[5, 10, 25, 100]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var props = ref.props;
var row = ref.row;
return [(row.submission)?[_c('td',[_c('router-link',{attrs:{"to":{
                      name: row.submission.status === 'complete' ? 'submission' : 'drafts',
                      params: {
                        id: row.submission.formId,
                        version: ("" + (row.submission.formVersion)),
                        submissionId: row.submission.id,
                      }
                    },"data-cy":"report-title"}},[_vm._v(" "+_vm._s(row.submission.title)+" ")])],1),_c('td',{attrs:{"data-cy":"report-date"}},[_vm._v(" "+_vm._s(_vm._f("date")(row.submission.reportDate))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(row.submission.created))+" ")]),_c('td',[_c('SubmissionStatus',{attrs:{"status":row.submission.status}})],1),_c('td',{staticClass:"text-xs-right"},[_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"data-cy":"submission-menu"}},on),[_vm._v(" more_horiz ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                          name: 'submission',
                          params: {
                            id: row.submission.formId,
                            version: ("" + (row.submission.formVersion)),
                            submissionId: row.submission.id,
                          }
                        },"data-cy":"view-submission"}},[_c('v-list-item-avatar',{attrs:{"color":"grey"}},[_c('v-icon',[_vm._v("remove_red_eye")])],1),_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{attrs:{"to":{
                          name: row.submission.status !== 'draft' ? 'edit-submission' : 'drafts',
                          params: {
                            id: row.submission.formId,
                            version: ("" + (row.submission.formVersion)),
                            submissionId: row.submission.id,
                          }
                        },"data-cy":"edit-submission"}},[_c('v-list-item-avatar',{attrs:{"color":"grey"}},[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"data-cy":"delete-submission"},on:{"click":function($event){return _vm.showDeleteSubmissionDialog(row.submission)}}},[_c('v-list-item-avatar',{attrs:{"color":"grey"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" delete ")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]:[_c('td',{staticClass:"submissions-table__error-cell",attrs:{"colspan":"999"}},[_c('s-alert',{attrs:{"value":true,"type":"error"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md8":"","px-3":"","md12":!row.error.resolution}},[_c('div',{staticClass:"s-alert__heading"},[_vm._v(" "+_vm._s(row.error.title)+" ")]),_vm._v(" "+_vm._s(row.error.description)+" ("+_vm._s(_vm._f("date")(row.date))+") ")]),(row.error.resolution)?_c('v-flex',{attrs:{"px-1":"","xs4":"","text-md-right":""}},[_c('SubmissionErrorActionBtn',{attrs:{"resolution":row.error.resolution}})],1):_vm._e()],1)],1)],1)]]}}])})],1)],1)],1)],1),_c('DeleteSubmissionDialog',{attrs:{"show":!!_vm.submissionToDelete,"submission":_vm.submissionToDelete,"loading":_vm.deleting,"error":_vm.deletingError},on:{"cancel":function($event){_vm.submissionToDelete = null},"delete":_vm.deleteSubmission}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }