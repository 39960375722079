import store from '@/store';

export function getReportRoute(
  reportType: string,
  date?: string,
  globalValues?: { sharedGlobalName: string; value: any }[],
): { name: string; params: any; query: any } {
  const report = store.getters.latestReports.find((r) => r.type !== null && r.type === reportType);

  if (!report) {
    throw new Error(`Couldn't find report to create route: ${reportType}`);
  }

  const route = {
    name: 'report',
    params: { id: report.id, version: report.version },
    query: {},
  };

  if (globalValues) {
    const globals = globalValues
      .reduce((globalsString, globalValue) => `${globalsString}${globalValue.sharedGlobalName},${
        globalValue.value
      };`, '');
    route.query = {
      ...route.query,
      globals,
    };
  }
  if (date) {
    route.query = {
      ...route.query,
      date,
    };
  }

  return route;
}

/**
 * Given a query object, extract globals from it if there are any
 * @param query
 */
export function getGlobalsFromQuery(
  query: any,
): { sharedGlobalName: string; value: any }[] {
  if (!query.globals) {
    return [];
  }

  const sharedGlobalsByName = store.getters['sharedGlobals/byName'];

  const globalPairs = query.globals.split(';');
  return globalPairs
    .filter((globalPairString) => globalPairString)
    .map((globalPairString) => {
      const nameAndValue = globalPairString.split(',');
      const { id } = sharedGlobalsByName[nameAndValue[0]];
      return {
        sharedGlobalId: id,
        sharedGlobalName: nameAndValue[0],
        value: nameAndValue[1],
      };
    });
}
