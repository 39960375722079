










import { Component, Vue, Prop } from 'vue-property-decorator';
import { SubmissionError } from '@/shared/types';
import { getReportRoute } from '@/shared/reportRouteHelpers';

@Component
export default class SubmissionErrorActionBtn extends Vue {
  @Prop({ required: true })
  private resolution: any;

  private get resolutionRoute() {
    try {
      return getReportRoute(
        this.resolution.route.reportType,
        this.resolution.route.date,
        this.resolution.route.globalDefaults,
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
