


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Submission } from '../../shared/types';

@Component({
  filters: {
    date(date) {
      if (!date) {
        return '';
      }

      return dayjs(date).format('DD/MM/YYYY HH:mm');
    },
  },
})
export default class DeleteSubmissionDialog extends Vue {
  @Prop()
  private submission: Submission;

  @Prop({ required: true })
  private show: boolean;

  @Prop()
  private loading: boolean;

  @Prop()
  private error: boolean;
}
