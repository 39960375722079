































































import Vue from 'vue';
import dayjs from 'dayjs';
import { Submission } from '../../rxdb/modules/submissions.module';

export default Vue.extend({
  data(): any {
    return {
      positionReportFormId: null,
    };
  },
  computed: {
    positionReportForm(): any {
      return this.$store.state.forms.latestForms.find((form) => {
        const formSchema = this.$store.state.formSchemas.formSchemas.find((f) => f.id === form.formSchemaId);
        return formSchema && formSchema.published && form.type === 'position-combined';
      });
    },
    hasPosition(): boolean {
      if (!this.positionReportForm) {
        return false;
      }
      const recentPositionReports: Submission[] = this.$store.state.submissions.latestSubmissions
        .filter((submission) => submission.formId === this.positionReportForm.id);
      const today = dayjs();
      return recentPositionReports.some((submission) => (
        today.isSame(submission.reportDate, 'day')
          && today.isSame(submission.reportDate, 'month')
          && today.isSame(submission.reportDate, 'year')
      ));
    },
    newPositionReportUrl(): string {
      if (!this.positionReportForm) {
        return '';
      }
      const date = new Date();
      date.setUTCHours(12, 0, 0, 0);
      return `/reports/${this.positionReportForm.id}/${this.positionReportForm.version}?date=${date.toISOString()}`;
    },
  },
});
