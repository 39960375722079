



























































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import SLayoutDefault from '@stratumfive/ui-baseplate/src/components/SLayoutDefault/SLayoutDefault.vue';
import SubmissionStatus from '@/components/shared/SubmissionStatus.vue';
import TodaysReports from '@/components/home/TodaysReports.vue';
import DeleteSubmissionDialog from '@/components/submission/DeleteSubmissionDialog.vue';
import SubmissionErrorActionBtn from '@/components/shared/SubmissionErrorActionBtn.vue';
import { LONG_DELAY } from '@/constants/ui';

export default Vue.extend({
  name: 'Home',

  components: {
    SLayoutDefault,
    SubmissionStatus,
    TodaysReports,
    DeleteSubmissionDialog,
    SubmissionErrorActionBtn,
  },

  filters: {
    date(date) {
      if (!date) return '';
      return dayjs(date).tz('Etc/UTC').format('DD/MM/YYYY');
    },
  },

  data(): any {
    return {
      snackbar: null,
      snackbarDismissTimeout: null,
      submissionHeaders: [
        { text: 'Report', sortable: false },
        { text: 'Report Date', sortable: true, key: 'date' },
        { text: 'Submitted On', sortable: false },
        { text: 'Status', sortable: false },
        { text: 'Actions', sortable: false, classNames: ['text-xs-right'] },
      ],

      pagination: {
        currentPage: 0,
        pageSize: 10,
        sortKey: '',
        sortDescending: false,
      },

      submissionToDelete: null,
      deleting: false,
      deletingError: false,
    };
  },

  computed: {
    totalSubmissions(): number {
      return this.$store.state.submissions.total;
    },

    submissions(): any[] {
      return this.$store.state.submissions.submissions;
    },

    currentItems(): any {
      return this.submissions.map((submission) => ({
        submission,
      }));
    },

    longDelay(): number {
      return LONG_DELAY;
    },
  },

  watch: {
    pagination: {
      deep: true,
      handler() {
        this.$store.dispatch('submissions/loadPage', {
          page: this.pagination.currentPage,
          limit: this.pagination.pageSize,
        });
      },
    },
  },

  created() {
    this.$store.dispatch('submissions/loadPage');
  },

  mounted() {
    if (this.$route.query?.submissionCreated) {
      this.snackbar = {
        color: 'success',
        message: 'Success: Your submission has been saved',
      };

      this.$router.replace({ query: {} });
    }

    this.snackbarDismissTimeout = setTimeout(() => {
      this.snackbar = null;
    }, LONG_DELAY);
  },

  beforeDestroy() {
    clearTimeout(this.snackbarDismissTimeout);
    this.snackbarDismissTimeout = null;
  },

  methods: {
    getSubmissionRoute(submission) {
      return {
        name: 'submission',
        params: {
          id: submission.formId,
          version: `${submission.formVersion}`,
          submissionId: submission.id,
        },
      };
    },

    showDeleteSubmissionDialog(submission) {
      this.submissionToDelete = submission;
    },

    async deleteSubmission(submission) {
      this.deleting = true;
      await this.$store.dispatch('submissions/remove', { submission });
      this.deleting = false;
      this.submissionToDelete = null;
    },
  },
});
